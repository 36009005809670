import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import GamesIntro from '../components/Games/GamesIntro/GamesIntro'
import GamesCategories from '../components/Games/GamesCategories/GamesCategories'
import OurGames from '../components/Games/OurGames/OurGames'
import { CategoryFeatureProps } from '../components/Games/CategoryFeatures/CategoryFeature'
import CategoryFeatures from '../components/Games/CategoryFeatures/CategoryFeatures'
import OurGamesDesktop from '../components/Games/OurGames/OurGamesDesktop'
import useShowAll from '../hooks/useShowAll'
import TestimonialsContainer from '../components/Index/Testimonials/TestimonialsContainer'
import HomeFAQs from '../components/Index/HomeFAQs/HomeFAQs'
import { Faq } from '../components/FAQs/faqs-list-interface'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import useTestimonials from '../components/Testimonials/useTestimonials'
import { GAMES } from '../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generatePokerTrailerSchema,
  generatePokerReviewSchemaAndroid,
  generateRummyTrailerSchema,
  generatePokerReviewSchemaIos,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: '/games',
  },
]

const Games: React.FC = () => {
  const [gameFaqsSt, setGameFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../components/FAQs/faqs-list').then(({ gameFaqs }) =>
      setGameFaqsSt(gameFaqs)
    )
  }, [])

  const data = useStaticQuery(graphql`
    {
      f1: file(relativePath: { eq: "track-opponent-1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 270, layout: CONSTRAINED)
        }
      }
      f2: file(relativePath: { eq: "track-opponent-2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 270, layout: CONSTRAINED)
        }
      }
      f3: file(relativePath: { eq: "track-opponent-3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 270, layout: CONSTRAINED)
        }
      }
      f4: file(relativePath: { eq: "track-opponent-4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 270, layout: CONSTRAINED)
        }
      }
    }
  `)

  const features: CategoryFeatureProps[] = [
    {
      title: 'Track your enemies in the social widget',
      imgSrc: data.f1.childImageSharp.gatsbyImageData,
      numberColor: '#37D6C2',
      subtitle: "See who you're fighting",
      imgWidth: '90px',
      imgHeight: '153px',
    },
    {
      title: 'Track your enemies in the social widget',
      imgSrc: data.f2.childImageSharp.gatsbyImageData,
      numberColor: '#FF3D3C',
      subtitle: 'Check their score anytime',
      imgWidth: '90px',
      imgHeight: '153px',
    },
    {
      title: 'Track your enemies in the social widget',
      imgSrc: data.f3.childImageSharp.gatsbyImageData,
      numberColor: '#2270DA',
      subtitle: 'See who got disconnected',
      imgWidth: '90px',
      imgHeight: '153px',
    },
    {
      title: 'Track your enemies in the social widget',
      imgSrc: data.f4.childImageSharp.gatsbyImageData,
      numberColor: '#FFD608',
      subtitle: 'Stay on top of any change',
      imgWidth: '90px',
      imgHeight: '153px',
    },
  ]

  const includePhoneImage = !useShowAll()
  const { allGamesTestimonials } = useTestimonials()

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={GAMES}>
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Mega Games: Explore, Play Learn & Earn Upto 1 Cr | Download Now"
        description="Explore all the games at Mega across three categories. Experience the best gameplay, 100% verified profile and real money winnings. Play with 2 or more players!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(gameFaqsSt)}
        videoSchema={[
          generatePokerTrailerSchema(),
          generateRummyTrailerSchema(),
        ]}
      />
      <GamesIntro addImageMask={false} disableOverlay />
      <GamesCategories />
      <OurGames />
      <OurGamesDesktop />
      <div
        style={{
          background: 'black',
          padding: '16px',
        }}
      >
        <CategoryFeatures
          features={features}
          title="Closely watch opponents with our social widget"
          includePhoneImage={includePhoneImage}
        />
      </div>
      <TestimonialsContainer testimonials={allGamesTestimonials} />
      <div
        style={{
          paddingTop: '100px',
          paddingBottom: '80px',
          background: '#000',
        }}
        className="games-faqs"
      >
        <HomeFAQs faqs={gameFaqsSt} />
      </div>
    </Layout>
  )
}

export default Games
