import React, { useState } from 'react'
import { Swipeable } from 'react-swipeable'

import './CategoryFeatures.scss'
import { Carousel } from 'react-bootstrap'
import CategoryFeature, { CategoryFeatureProps } from './CategoryFeature'

interface Props {
  features: CategoryFeatureProps[]
  title: string
  includePhoneImage?: boolean
}

const CategoryFeatures: React.FC<Props> = ({
  features,
  title,
  includePhoneImage = false,
}) => {
  const [visibleFeature, setVisibleFeature] = useState(0)

  const renderCategoryFeature = (f: CategoryFeatureProps) => {
    return (
      <CategoryFeature
        key={f.numberColor}
        title={f.title}
        imgSrc={f.imgSrc}
        imgWidth={f.imgWidth}
        imgHeight={f.imgHeight}
        numberColor={f.numberColor}
        subtitle={f.subtitle}
        includePhoneImage={includePhoneImage}
      />
    )
  }

  return (
    <div id="category-features">
      <Swipeable
        onSwipedLeft={() => {
          setVisibleFeature((visibleFeature + 1) % features.length)
        }}
        onSwipedRight={() => {
          const len = features.length
          setVisibleFeature((visibleFeature - 1 + len) % len)
        }}
        preventDefaultTouchmoveEvent
        className="d-block d-md-none"
      >
        <Carousel
          controls={false}
          indicators={false}
          interval={1000 * 1000}
          onSlide={i => {
            setVisibleFeature(i)
          }}
          activeIndex={visibleFeature}
        >
          {features.map(f => {
            return (
              <Carousel.Item key={f.numberColor}>
                {renderCategoryFeature(f)}
              </Carousel.Item>
            )
          })}
        </Carousel>

        <div className="number-list">
          {features.map((f, i) => {
            return (
              <button
                key={f.numberColor}
                className={`f${i + 1}${
                  visibleFeature === i ? '' : ' disabled'
                }`}
                onClick={(): void => {
                  setVisibleFeature(i)
                }}
                type="button"
                style={{
                  color: f.numberColor,
                  borderBottom: `3px ${f.numberColor} solid`,
                  borderRadius: '2px',
                }}
              >
                {i + 1}
              </button>
            )
          })}
        </div>
      </Swipeable>

      <div className="d-none d-md-block">
        {title ? <h2 className="features-title">{title}</h2> : null}
        <div className="desktop-grid">
          {features.map(f => {
            return (
              <React.Fragment key={f.numberColor}>
                {renderCategoryFeature(f)}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CategoryFeatures
