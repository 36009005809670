import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import './CategoryFeature.scss'
import Title from '../../Title/Title'

export interface CategoryFeatureProps {
  title: string
  imgSrc: FluidObject
  imgWidth?: string
  imgHeight?: string
  numberColor: string
  subtitle?: string
  includePhoneImage?: boolean
}

const CategoryFeature: React.FC<CategoryFeatureProps> = ({
  title,
  imgSrc,
  imgWidth,
  imgHeight,
  subtitle,
  includePhoneImage = false,
}) => {
  const style: { [index: string]: string } = {}
  if (imgWidth) style.width = imgWidth
  if (imgHeight) style.height = imgHeight

  return (
    <div className="category-feature">
      <Title color="white" className="cf-title">
        {title}
      </Title>
      <div className={`feature-image ${includePhoneImage ? 'phone-bg' : ''}`}>
        <GatsbyImage
          image={imgSrc}
          loading="eager"
          alt={title}
          style={style}
          className="image"
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
      {subtitle && <div className="subtitle">{subtitle}</div>}
    </div>
  )
}

export default CategoryFeature
