import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import './OurGames.scss'
import { Container } from 'react-bootstrap'
import Title from '../../Title/Title'
import GameCard from '../GameCard/GameCard'
import * as links from '../../internal-links'
import gameDescription from '../games-description'

const OurGames: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      Poker: file(
        relativePath: { eq: "Mobile Poster Assets/TEXAS_YOUTUBE Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      Rummy: file(
        relativePath: { eq: "Mobile Poster Assets/preview copy 2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <div id="our-games">
      <Container>
        <Title color="white">Our Games</Title>

        <div id="games">
          <div>
            <GameCard
              category="cards"
              showCategory
              name="Hold 'em Poker"
              showName
              description={gameDescription.Poker.long}
              showDescription
              gameImage={data.Poker.childImageSharp.gatsbyImageData}
              gameUrl={links.POKER}
              trailerVideo="https://player.vimeo.com/video/475786286?title=0&byline=0&portrait=0&autoplay=1&background=0"
            />
          </div>

          <div>
            <GameCard
              category="cards"
              showCategory
              name="Rummy"
              showName
              description={gameDescription.Rummy.long}
              showDescription
              gameImage={data.Rummy.childImageSharp.gatsbyImageData}
              trailerVideo="https://player.vimeo.com/video/475786338?title=0&byline=0&portrait=0&autoplay=1&background=0"
              gameUrl={links.RUMMY}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default OurGames
