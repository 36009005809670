import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap'
import DesktopLarge from '../GameCard/DesktopLarge'
import './OurGamesDesktop.scss'
import * as links from '../../internal-links'
import skills from '../game-skills'
import gameDescription from '../games-description'

const OurGamesDesktop: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      Poker: file(
        relativePath: { eq: "desktop-posters/Horizontle_TexasHoldem Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      Rummy: file(relativePath: { eq: "desktop-posters/Group 10 Copy 2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      thPoker: file(
        relativePath: { eq: "thumbnails/Verticle_TexasHoldem.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 312, layout: CONSTRAINED)
        }
      }
      thRummy: file(relativePath: { eq: "thumbnails/Rummy-Thumbnails.png" }) {
        childImageSharp {
          gatsbyImageData(width: 312, layout: CONSTRAINED)
        }
      }
      PokerDesk: file(relativePath: { eq: "Desktop poster assets/hhhh.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      RummyDesk: file(
        relativePath: { eq: "Desktop poster assets/_RummyBanner_.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div id="OurGamesDesktop">
      <Container>
        <Row>
          <Col lg={12} className="desktop-medium-card">
            <DesktopLarge
              category="cards"
              showCategory
              name="Rummy"
              showName
              description={gameDescription.Rummy.short}
              showDescription
              gameImage={data.RummyDesk.childImageSharp.gatsbyImageData}
              gameThumbnail={data.thRummy.childImageSharp.gatsbyImageData}
              gameUrl={links.RUMMY}
              trailerVideo="https://player.vimeo.com/video/475786338?title=0&byline=0&portrait=0&autoplay=1&background=0"
              skills={skills.Rummy}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} className="desktop-medium-card">
            <DesktopLarge
              category="cards"
              showCategory
              name="Hold 'em Poker"
              showName
              description={gameDescription.Poker.short}
              showDescription
              gameImage={data.PokerDesk.childImageSharp.gatsbyImageData}
              gameThumbnail={data.thPoker.childImageSharp.gatsbyImageData}
              gameUrl={links.POKER}
              trailerVideo="https://player.vimeo.com/video/475786286?title=0&byline=0&portrait=0&autoplay=1&background=0"
              skills={skills.Poker}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OurGamesDesktop
