import React from 'react'
import { TestimonialProps } from '../../Testimonials/Testimonial'
import HomeTestimonials from './HomeTestimonials'

interface Props {
  testimonials: TestimonialProps[]
  lang?: string
  engHeading?: string
}

const TestimonialsContainer: React.FC<Props> = ({
  testimonials,
  lang,
  engHeading,
}) => {
  return (
    <div id="testimonials-container">
      <HomeTestimonials
        color="black"
        testimonials={testimonials}
        lang={lang}
        title={{
          eng: engHeading,
        }}
      />
    </div>
  )
}

export default TestimonialsContainer
