import React from 'react'
import './GamesCategories.scss'
import { Container } from 'react-bootstrap'

const GamesCategories: React.FC = () => {
  return (
    <div className="black-bg">
      <Container>
        <div id="games-categories">
          <h1>
            Choose your favourite card games, <br />
            Play Rummy and Poker on Mega.
          </h1>
        </div>
      </Container>
    </div>
  )
}

export default GamesCategories
